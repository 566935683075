/**
 * axios封装
 * 请求拦截、响应拦截、错误统一处理
 */
import axios from 'axios'
import router from '@/router'

/**
 * 跳转登录页
 * 携带当前页面路由，以期在登录页面完成登录后返回当前页面
 */
const toLogin = async () => {
  localStorage.removeItem('userInfo')

  this.$message.warning({
    message: '服务器用户登录信息认证失败，请重新登录',
    duration: 1000,
    onClose() {
      if (['/login', '/register'].indexOf(router.currentRoute.value.path) >= 0) return router.push('/login')
      router.push({
        path: '/login',
        query: { goto: router.currentRoute.value.path },
      })
    },
  })
}

/**
 * 请求失败后的错误统一处理
 * @param {Number} status 请求失败的状态码
 */
const errorHandle = (status, msg) => {
  // 状态码判断
  switch (status) {
    case 300:
      toLogin()
      break
    case 404:
      this.$message.error('请求的资源不存在' + msg)
      break
    case 100:
      if (msg) this.$message.error(msg)
      break
    default:
      this.$message.error('服务器异常，请刷新页面重试')
  }
}

// 创建axios实例
let instance = axios.create({ timeout: 1000 * 60 })
// 设置post请求头
instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

/**
 * 请求拦截器
 * 每次请求前，如果存在token则在请求头中携带token
 */
instance.interceptors.request.use(
  (config) => {
    const token = !window.localStorage.getItem('userInfo')
      ? null
      : JSON.parse(window.localStorage.getItem('userInfo')).token
    if (token) config.headers.Authorization = token
    return config
  },
  (error) => Promise.error(error)
)

instance.interceptors.response.use(
  (res) => {
    if (
      [204].indexOf(res.status) >= 0 ||
      res.data.code === 200 ||
      (res.headers && res.headers['content-type'] && res.headers['content-type'].indexOf('text/html;') >= 0)
    ) {
      return Promise.resolve(res.data)
    }
    errorHandle(res.data.code, res.data.msg)
    return Promise.reject(res.data)
  },
  (error) => {
    const { response } = error
    if (response) {
      errorHandle(response.status, response.data.message)
      return Promise.reject(response)
    } else {
      return Promise.reject(error)
    }
  }
)

export default instance
