import { saveUserSource } from '@/request/api/common'
import { createRouter, createWebHistory } from 'vue-router'
const Home = () => import(/* webpackChunkName: "Home" */ '../views/Home.vue')
const Index = () => import(/* webpackChunkName: "Home" */ '../components/index/Index.vue')
// 列表页面
const LiveList = () => import(/* webpackChunkName: "List" */ '../components/list/LiveList.vue')
const SearchList = () => import(/* webpackChunkName: "List" */ '../components/list/SearchList.vue')
const MkCourseList = () => import(/* webpackChunkName: "List" */ '../components/list/MkCourseList.vue')
const MkArticleList = () => import(/* webpackChunkName: "List" */ '../components/list/MkArticleList.vue')
const WhiteBookList = () => import(/* webpackChunkName: "List" */ '../components/list/WhiteBookList.vue')
const OffLineList = () => import(/* webpackChunkName: "List" */ '../components/list/OffLineList.vue')
// 详情页面
const LiveDetails = () => import(/* webpackChunkName: "Details" */ '../components/details/LiveDetails.vue')
const CourseDetails = () => import(/* webpackChunkName: "Details" */ '../components/details/CourseDetails.vue')
const WhiteBookDetails = () => import(/* webpackChunkName: "Details" */ '../components/details/WhiteBookDetails.vue')
const MkArticleDetails = () => import(/* webpackChunkName: "Details" */ '../components/details/MkArticleDetails.vue')
const OffLineDetails = () => import(/* webpackChunkName: "Details" */ '../components/details/OffLineDetails.vue')
const OffLineApply = () => import(/* webpackChunkName: "Details" */ '../components/details/OffLineApply.vue')
const ApplyResult = () => import(/* webpackChunkName: "Details" */ '../components/details/ApplyResult.vue')
const PayPage = () => import(/* webpackChunkName: "Details" */ '../components/details/PayPage.vue')
const PayPageLC = () => import(/* webpackChunkName: "Details" */ '../components/details/PayPageLC.vue')
const WXPayment = () => import(/* webpackChunkName: "Details" */ '../components/details/WXPayment.vue')
// 个人中心
const MyCenter = () => import(/* webpackChunkName: "InfoCenter" */ '../components/personalCenter/MyCenter.vue')
const MyData = () => import(/* webpackChunkName: "InfoCenter" */ '../components/personalCenter/myInfo/MyData.vue')
const MyCourse = () => import(/* webpackChunkName: "InfoCenter" */ '../components/personalCenter/myInfo/MyCourse.vue')
const MyOrder = () => import(/* webpackChunkName: "InfoCenter" */ '../components/personalCenter/myInfo/MyOrder.vue')
const OrderTotal = () =>
  import(/* webpackChunkName: "InfoCenter" */ '../components/personalCenter/myInfo/OrderTotal.vue')
const ActiveOrderDetails = () =>
  import(/* webpackChunkName: "InfoCenter" */ '../components/personalCenter/myInfo/ActiveOrderDetails.vue')
const MyRate = () => import(/* webpackChunkName: "InfoCenter" */ '../components/personalCenter/myInfo/MyRate.vue')
const MyCoupon = () => import(/* webpackChunkName: "InfoCenter" */ '../components/personalCenter/myInfo/MyCoupon.vue')
// 直播间
const LivePlayback = () => import(/* webpackChunkName: "LiveRoom" */ '../components/liveRoom/LivePlayback.vue')
const LiveRoom = () => import(/* webpackChunkName: "LiveRoom" */ '../components/liveRoom/LiveRoom.vue')

const routes = [
  {
    path: '/:pathMatch(.*)',
    redirect: '/',
  },
  {
    path: '/',
    redirect: 'index',
    name: 'home',
    component: Home,
    children: [
      {
        path: 'index',
        name: 'index',
        component: Index,
        meta: {
          title: 'Facebook培训-TikTok培训-Google培训-海外广告营销学院-YinoLink周5出海',
          content: {
            keywords: 'Facebook培训，Facebook课程，TikTok课程，Google课程，海外营销推广技巧，海外广告营销',
            description:
              'YinoLink周5出海是专业的海外营销服务商YinoLink易诺旗下专业的知识输出平台，凭借长达10年的Facebook、Google等海外广告投放经验，由专业的海外广告投放人员定期进行Facebook广告培训、TikTok广告培训，分享Facebook广告、TikTok广告、Google广告等海外广告投放技巧，以供广大跨境电商卖家朋友学习海外推广经验，进行海外广告营销的实操学习',
          },
        },
      },
      // 列表路由
      {
        path: 'liveList',
        name: 'liveList',
        component: LiveList,
        meta: {
          title: 'Facebook直播课程-Facebook广告投放课程-YinoLink周5出海',
          content: {
            keywords: 'Facebook培训，Facebook课程，TikTok课程，Google课程，海外营销推广技巧，海外广告营销',
            description:
              'YinoLink周5出海是专业的海外营销服务商YinoLink易诺旗下专业的知识输出平台，凭借长达10年的Facebook、Google等海外广告投放经验，由专业的海外广告投放人员定期进行Facebook广告培训、TikTok广告培训，分享Facebook广告、TikTok广告、Google广告等海外广告投放技巧，以供广大跨境电商卖家朋友学习海外推广经验，进行海外广告营销的实操学习',
          },
        },
        props($route) {
          return {
            indexMenuTitle: $route.query.name,
          }
        },
      },
      {
        path: 'searchList',
        name: 'SearchList',
        component: SearchList,
        meta: {
          title: '搜索-YinoLink周5出海',
          content: {
            keywords: 'Facebook培训，Facebook课程，TikTok课程，Google课程，海外营销推广技巧，海外广告营销',
            description:
              'YinoLink周5出海是专业的海外营销服务商YinoLink易诺旗下专业的知识输出平台，凭借长达10年的Facebook、Google等海外广告投放经验，由专业的海外广告投放人员定期进行Facebook广告培训、TikTok广告培训，分享Facebook广告、TikTok广告、Google广告等海外广告投放技巧，以供广大跨境电商卖家朋友学习海外推广经验，进行海外广告营销的实操学习',
          },
        },
        props($route) {
          return {
            indexTitle: $route.query.title,
            keywords: $route.query.keywords,
          }
        },
      },
      {
        path: 'courseList',
        name: 'MkCourseList',
        component: MkCourseList,
        meta: {
          title: 'Facebook广告课程-海外推广课程-YinoLink周5出海',
          content: {
            keywords: 'Facebook培训，Facebook课程，TikTok课程，Google课程，海外营销推广技巧，海外广告营销',
            description:
              'YinoLink周5出海是专业的海外营销服务商YinoLink易诺旗下专业的知识输出平台，凭借长达10年的Facebook、Google等海外广告投放经验，由专业的海外广告投放人员定期进行Facebook广告培训、TikTok广告培训，分享Facebook广告、TikTok广告、Google广告等海外广告投放技巧，以供广大跨境电商卖家朋友学习海外推广经验，进行海外广告营销的实操学习',
          },
        },
        props($route) {
          return {
            indexMenuTitle: $route.query.name,
          }
        },
      },
      {
        path: 'articleList',
        name: 'MKArticleList',
        component: MkArticleList,
        meta: {
          title: 'Facebook营销干货-YinoLink周5出海',
          content: {
            keywords: 'Facebook培训，Facebook课程，TikTok课程，Google课程，海外营销推广技巧，海外广告营销',
            description:
              'YinoLink周5出海是专业的海外营销服务商YinoLink易诺旗下专业的知识输出平台，凭借长达10年的Facebook、Google等海外广告投放经验，由专业的海外广告投放人员定期进行Facebook广告培训、TikTok广告培训，分享Facebook广告、TikTok广告、Google广告等海外广告投放技巧，以供广大跨境电商卖家朋友学习海外推广经验，进行海外广告营销的实操学习',
          },
        },
        props($route) {
          return {
            indexMenuTitle: $route.query.name,
          }
        },
      },
      {
        path: 'whiteBookList',
        name: 'WhiteBookList',
        component: WhiteBookList,
        meta: {
          title: 'Facebook白皮书-海外推广行业报告-YinoLink周5出海',
          content: {
            keywords: 'Facebook培训，Facebook课程，TikTok课程，Google课程，海外营销推广技巧，海外广告营销',
            description:
              'YinoLink周5出海是专业的海外营销服务商YinoLink易诺旗下专业的知识输出平台，凭借长达10年的Facebook、Google等海外广告投放经验，由专业的海外广告投放人员定期进行Facebook广告培训、TikTok广告培训，分享Facebook广告、TikTok广告、Google广告等海外广告投放技巧，以供广大跨境电商卖家朋友学习海外推广经验，进行海外广告营销的实操学习',
          },
        },
        props($route) {
          return {
            whiteBookId: $route.query.id,
          }
        },
      },
      {
        path: 'offLineList',
        name: 'OffLineList',
        component: OffLineList,
        meta: {
          title: '线下活动-YinoLink周5出海',
          content: {
            keywords: 'Facebook培训，Facebook课程，TikTok课程，Google课程，海外营销推广技巧，海外广告营销',
            description:
              'YinoLink周5出海是专业的海外营销服务商YinoLink易诺旗下专业的知识输出平台，凭借长达10年的Facebook、Google等海外广告投放经验，由专业的海外广告投放人员定期进行Facebook广告培训、TikTok广告培训，分享Facebook广告、TikTok广告、Google广告等海外广告投放技巧，以供广大跨境电商卖家朋友学习海外推广经验，进行海外广告营销的实操学习',
          },
        },
      },
      // 详情路由
      {
        path: 'liveDetails',
        name: 'LiveDetails',
        component: LiveDetails,
        props($route) {
          return {
            liveId: $route.query.id,
          }
        },
      },
      {
        path: 'courseDetails',
        name: 'CourseDetails',
        component: CourseDetails,
        props($route) {
          return {
            courseId: $route.query.id,
          }
        },
      },
      {
        path: 'whiteBookDetails',
        name: 'WhiteBookDetails',
        component: WhiteBookDetails,
        props($route) {
          return {
            bookId: $route.query.id,
          }
        },
      },
      {
        path: 'articleDetails',
        name: 'MkArticleDetails',
        component: MkArticleDetails,
        props($route) {
          return {
            articleId: $route.query.id,
          }
        },
      },
      {
        path: 'offLineDetails',
        name: 'OffLineDetails',
        component: OffLineDetails,
        props($route) {
          return {
            offlineId: $route.query.id,
          }
        },
      },
      {
        path: 'offLineApply',
        name: 'OffLineApply',
        component: OffLineApply,
        meta: {
          title: '活动申请-YinoLink周5出海',
        },
        props($route) {
          return {
            ticketId: $route.query.id,
          }
        },
      },
      {
        path: 'applyResult',
        name: 'ApplyResult',
        component: ApplyResult,
        meta: {
          title: '结果页-YinoLink周5出海',
        },
        props($route) {
          return {
            orderId: $route.query.id,
          }
        },
      },
      {
        path: 'payPage',
        name: 'PayPage',
        component: PayPage,
        meta: {
          title: '订单支付-YinoLink周5出海',
        },
        props($route) {
          return {
            orderId: $route.query.id,
          }
        },
      },
      {
        path: 'payPageLC',
        name: 'PayPageLC',
        component: PayPageLC,
        meta: {
          title: '订单支付-YinoLink周5出海',
        },
        props($route) {
          return {
            orderId: $route.query.id,
          }
        },
      },
      {
        path: 'wxPayment',
        name: 'WXPayment',
        component: WXPayment,
        meta: {
          title: '微信支付-YinoLink周5出海',
        },
      },
      // 个人中心
      {
        path: 'myCenter',
        redirect: 'myCenter/myData',
        name: 'MyCenter',
        component: MyCenter,
        children: [
          {
            path: 'myData',
            name: 'MyData',
            component: MyData,
            meta: {
              title: '个人资料-YinoLink周5出海',
              content: {
                keywords: 'Facebook培训，Facebook课程，TikTok课程，Google课程，海外营销推广技巧，海外广告营销',
                description:
                  'YinoLink周5出海是专业的海外营销服务商YinoLink易诺旗下专业的知识输出平台，凭借长达10年的Facebook、Google等海外广告投放经验，由专业的海外广告投放人员定期进行Facebook广告培训、TikTok广告培训，分享Facebook广告、TikTok广告、Google广告等海外广告投放技巧，以供广大跨境电商卖家朋友学习海外推广经验，进行海外广告营销的实操学习',
              },
            },
          },
          {
            path: 'myCourse',
            name: 'MyCourse',
            component: MyCourse,
            meta: {
              title: '我的课程-YinoLink周5出海',
              content: {
                keywords: 'Facebook培训，Facebook课程，TikTok课程，Google课程，海外营销推广技巧，海外广告营销',
                description:
                  'YinoLink周5出海是专业的海外营销服务商YinoLink易诺旗下专业的知识输出平台，凭借长达10年的Facebook、Google等海外广告投放经验，由专业的海外广告投放人员定期进行Facebook广告培训、TikTok广告培训，分享Facebook广告、TikTok广告、Google广告等海外广告投放技巧，以供广大跨境电商卖家朋友学习海外推广经验，进行海外广告营销的实操学习',
              },
            },
          },
          {
            path: 'myOrder',
            name: 'MyOrder',
            redirect: 'myOrder/orderTotal',
            component: MyOrder,
            meta: {
              title: '我的订单-YinoLink周5出海',
              content: {
                keywords: 'Facebook培训，Facebook课程，TikTok课程，Google课程，海外营销推广技巧，海外广告营销',
                description:
                  'YinoLink周5出海是专业的海外营销服务商YinoLink易诺旗下专业的知识输出平台，凭借长达10年的Facebook、Google等海外广告投放经验，由专业的海外广告投放人员定期进行Facebook广告培训、TikTok广告培训，分享Facebook广告、TikTok广告、Google广告等海外广告投放技巧，以供广大跨境电商卖家朋友学习海外推广经验，进行海外广告营销的实操学习',
              },
            },
            children: [
              {
                path: 'orderTotal',
                name: 'OrderTotal',
                component: OrderTotal,
              },
              {
                path: 'orderDetail',
                name: 'ActiveOrderDetails',
                component: ActiveOrderDetails,
                props($route) {
                  return {
                    activeOrderId: $route.query.id,
                  }
                },
              },
            ],
          },
          {
            path: 'myRate',
            name: 'MyRate',
            component: MyRate,
            meta: {
              title: '我的评价-YinoLink周5出海',
              content: {
                keywords: 'Facebook培训，Facebook课程，TikTok课程，Google课程，海外营销推广技巧，海外广告营销',
                description:
                  'YinoLink周5出海是专业的海外营销服务商YinoLink易诺旗下专业的知识输出平台，凭借长达10年的Facebook、Google等海外广告投放经验，由专业的海外广告投放人员定期进行Facebook广告培训、TikTok广告培训，分享Facebook广告、TikTok广告、Google广告等海外广告投放技巧，以供广大跨境电商卖家朋友学习海外推广经验，进行海外广告营销的实操学习',
              },
            },
          },
          {
            path: 'myCoupon',
            name: 'MyCoupon',
            component: MyCoupon,
            meta: {
              title: '我的优惠券-YinoLink周5出海',
              content: {
                keywords: 'Facebook培训，Facebook课程，TikTok课程，Google课程，海外营销推广技巧，海外广告营销',
                description:
                  'YinoLink周5出海是专业的海外营销服务商YinoLink易诺旗下专业的知识输出平台，凭借长达10年的Facebook、Google等海外广告投放经验，由专业的海外广告投放人员定期进行Facebook广告培训、TikTok广告培训，分享Facebook广告、TikTok广告、Google广告等海外广告投放技巧，以供广大跨境电商卖家朋友学习海外推广经验，进行海外广告营销的实操学习',
              },
            },
          },
        ],
      },
    ],
  },
  {
    path: '/livePlayback',
    name: 'LivePlayback',
    component: LivePlayback,
    meta: {
      title: '直播回放-YinoLink周5出海',
      content: {
        keywords: 'Facebook培训，Facebook课程，TikTok课程，Google课程，海外营销推广技巧，海外广告营销',
        description:
          'YinoLink周5出海是专业的海外营销服务商YinoLink易诺旗下专业的知识输出平台，凭借长达10年的Facebook、Google等海外广告投放经验，由专业的海外广告投放人员定期进行Facebook广告培训、TikTok广告培训，分享Facebook广告、TikTok广告、Google广告等海外广告投放技巧，以供广大跨境电商卖家朋友学习海外推广经验，进行海外广告营销的实操学习',
      },
    },
    props($route) {
      return {
        liveRoomId: $route.query.id,
        courseId: $route.query.course_id,
      }
    },
  },
  {
    path: '/liveRoom',
    name: 'LiveRoom',
    component: LiveRoom,
    meta: {
      title: '直播间-YinoLink周5出海',
      content: {
        keywords: 'Facebook培训，Facebook课程，TikTok课程，Google课程，海外营销推广技巧，海外广告营销',
        description:
          'YinoLink周5出海是专业的海外营销服务商YinoLink易诺旗下专业的知识输出平台，凭借长达10年的Facebook、Google等海外广告投放经验，由专业的海外广告投放人员定期进行Facebook广告培训、TikTok广告培训，分享Facebook广告、TikTok广告、Google广告等海外广告投放技巧，以供广大跨境电商卖家朋友学习海外推广经验，进行海外广告营销的实操学习',
      },
    },
    props($route) {
      return {
        liveRoomId: $route.query.id,
        courseId: $route.query.course_id,
      }
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

// 路由导航守卫
router.beforeEach((to, from, next) => {
  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  // 路由发生变化修改页面title
  if (to.meta.title) {
    document.title = to.meta.title
  }
  // 路由发生变化改变description和keyword
  if (to.meta.content) {
    const head = document.getElementsByTagName('head')
    const meta = document.createElement('meta')
    document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords)
    document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
    meta.content = to.meta.content
    head[0].appendChild(meta)
  }

  if (to.path === '/login') return next()
  // const tokenStr = window.localStorage.getItem('userInfo')
  // if (!tokenStr || tokenStr == 'undefined') return next('/login')
  next()
})

router.afterEach((to, from, next) => {
  // 保存用户链接来源
  const { source, path } = to.query
  if (source || path) {
    saveUserSource({ source, path, type: 2 })
  }

  setTimeout(() => {
    //End 百度统计
    var _hmt = _hmt || []
    ;(function () {
      var hm = document.createElement('script')
      hm.src = 'https://hm.baidu.com/hm.js?dd9199fd728df0a244fe51fcef13a627'
      var s = document.getElementsByTagName('script')[0]
      s.parentNode.insertBefore(hm, s)
    })()
    // 百度统计

    // Google Tag Manager
    ;(function (w, d, s, l, i) {
      w[l] = w[l] || []
      w[l].push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js',
      })
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : ''
      j.async = true
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
      f.parentNode.insertBefore(j, f)
    })(window, document, 'script', 'dataLayer', 'GTM-KXD6VPF')
    //  End Google Tag Manager

    // Bytedance Tracking
    ;(function (r, d, s) {
      r._tt_config = true
      var _baq = (r._baq = r._baq || [])
      _baq.methods = ['track', 'off', 'on']
      _baq.factory = function (method) {
        return function () {
          var args = Array.prototype.slice.call(arguments)
          args.unshift(method)
          _baq.push(args)
          return _baq
        }
      }
      for (var i = 0; i < _baq.methods.length; i++) {
        var key = _baq.methods[i]
        _baq[key] = _baq.factory(key)
      }
      _baq.load = function () {
        var js,
          fjs = d.getElementsByTagName(s)[0]
        js = d.createElement(s)
        js.src = 'https://analytics.oceanengine.com/api/v2/js/sdk'
        fjs.parentNode.insertBefore(js, fjs)
      }
      _baq.load()
      if (_baq.invoked) {
        return
      }
      _baq.invoked = true
      _baq.track('pageview')
    })(window, document, 'script')
    // End Bytedance Tracking

    !(function (f, b, e, v, n, t, s) {
      if (f.fbq) return
      n = f.fbq = function () {
        n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
      }
      if (!f._fbq) f._fbq = n
      n.push = n
      n.loaded = !0
      n.version = '2.0'
      n.queue = []
      t = b.createElement(e)
      t.async = !0
      t.src = v
      s = b.getElementsByTagName(e)[0]
      s.parentNode.insertBefore(t, s)
    })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js')
    fbq('init', '491284102096723')
    fbq('track', 'PageView')
  })
})
export default router
