import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue'
import moment from 'moment'
import mitt from 'mitt'
import VueVideoPlayer from '@videojs-player/vue'
import 'video.js/dist/video-js.css'
import 'ant-design-vue/dist/antd.css'
import 'ant-design-vue/dist/antd.less'
import { message } from 'ant-design-vue'
import { TcPlayer } from '@/assets/js/TcPlayer-module-2.4.5'
import './assets/zwfonts/iconfont.css'
import './assets/css/global.css'
import VueClipboard from 'vue-clipboard2'
import VueCropper from 'vue-cropper'
import 'vue-cropper/dist/index.css'
import base from '@/request/base'

// 导入axios
import axios from 'axios'

const app = createApp(App)
// 测试接口
// axios.defaults.baseURL = 'https://actest.yinolink.com/index/'
// 正式接口
axios.defaults.baseURL = `${base.url}/newindex/`
app.config.globalProperties.$http = axios
app.config.globalProperties.$moment = moment
app.config.globalProperties.$bus = mitt()
app.config.globalProperties.$message = message
app.config.globalProperties.TcPlayer = TcPlayer
// axios请求拦截器
axios.interceptors.request.use((config) => {
  var userToken = window.localStorage.getItem('userInfo')
  if (userToken) {
    if (userToken !== 'undefined') {
      userToken = JSON.parse(userToken).token
      config.headers.Authorization = userToken
    }
  }
  return config
})
// 声明响应拦截器
axios.interceptors.response.use((response) => {
  return response
})
app.use(store).use(router).use(Antd).use(VueVideoPlayer).use(VueClipboard).use(VueCropper).mount('#app')
