<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <router-view />
    </div>
  </a-config-provider>
</template>

<script>
import zhCN from 'ant-design-vue/es/locale/zh_CN'
export default {
  name: 'App',
  data() {
    return {
      locale: zhCN,
      userInfo: null,
      source: '',
    }
  },
  watch: {
    $route(to, from) {
      if (window.location.search.indexOf('source') != -1) {
        this.source = window.location.search.split('source')[1].split('=')[1]
        window.localStorage.setItem('source', this.source)
      }
      if (to.path != from.path && this.userInfo != null) {
        this.getLoginStatus()
      }
    },
  },
  mounted() {
    this.userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
    if (this.userInfo != null) {
      this.getLoginStatus()
    } else {
      this.getLogin()
    }
  },
  methods: {
    async getLogin() {
      if (location.search.indexOf('&') != -1) {
        var token = location.search.split('am=')[1]
        const { data: res } = await this.$http.post('user/get_user', {
          token: token,
        })
        if (res.code == 200) {
          var str = location.href.split('am=')[0]
          var href = str.slice(0, str.length - 1)
          window.localStorage.setItem('userInfo', JSON.stringify(res.user))
          window.location.href = href
        }
      }
    },
    async getLoginStatus() {
      const { data: res } = await this.$http.post('user/sign_out')
      if (res.code == 300) {
        window.localStorage.clear()
        location.reload()
        this.warning()
      }
    },
    warning() {
      Modal.warning({
        title: '登陆失效',
        content: '请重新登录',
      })
    },
  },
}
</script>

<style>
#app {
  font-family: 'microsoft yahei', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}
body {
  /* min-width: 1200px; */
}
</style>
