import axios from '@/request/http'
import base from '@/request/base'

/**
 * 存储用户链接来源
 * @param {object} params
 */
export const saveUserSource = (params) => {
  return axios.get(`https://yinocloud.yinolink.com/cloud/user/user_source`, { params })
}
